import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import PrimaryButton from '../Buttons/PrimaryButton';

import Input from './Input';
import InputSelect from './InputSelect';
import InputArea from './InputArea';

const GetInTouchForm = ({ onSubmit }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validCompany, setValidCompany] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);

  const options = [
    {
      value: 'farmer',
      label: intl.formatMessage({ id: 'contact.role.producer' }),
    },
    {
      value: 'merchant',
      label: intl.formatMessage({ id: 'contact.role.merchant' }),
    },
    {
      value: 'investor',
      label: intl.formatMessage({ id: 'contact.role.investor' }),
    },
    { value: 'other', label: intl.formatMessage({ id: 'contact.role.other' }) },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setFirstSubmit(true);
    if (validEmail && validPhone && validName && role) {
      setLoading(true);
      onSubmit(e);
    }
  };

  return (
    <form
      className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3"
      onSubmit={handleSubmit}
    >
      <Input
        type="text"
        label="contact.name"
        placeholder="contact.name.placeholder"
        errorMessage={intl.formatMessage({ id: 'contact.error.name' })}
        emptyMessage={intl.formatMessage({ id: 'contact.error.empty' })}
        value={name}
        setValue={setName}
        setIsValid={setValidName}
        isValid={validName}
        firstSubmit={firstSubmit}
        validate
      />
      <Input
        type="text"
        label="contact.email"
        placeholder="contact.email.placeholder"
        errorMessage={intl.formatMessage({ id: 'contact.error.email' })}
        emptyMessage={intl.formatMessage({ id: 'contact.error.empty' })}
        value={email}
        setValue={setEmail}
        setIsValid={setValidEmail}
        isValid={validEmail}
        firstSubmit={firstSubmit}
        validate
      />
      <Input
        type="tel"
        label="contact.phone"
        placeholder="contact.phone.placeholder"
        errorMessage={intl.formatMessage({ id: 'contact.error.phone' })}
        emptyMessage={intl.formatMessage({ id: 'contact.error.empty' })}
        value={phone}
        setValue={setPhone}
        setIsValid={setValidPhone}
        isValid={validPhone}
        firstSubmit={firstSubmit}
        validate
      />
      <InputSelect
        label="contact.role.label"
        options={options}
        value={role}
        setValue={setRole}
        emptyMessage={intl.formatMessage({ id: 'contact.error.empty' })}
        firstSubmit={firstSubmit}
        validate
      />
      <InputArea
        type="text"
        label="contact.message"
        placeholder="contact.message.placeholder"
        className="order-last"
        value={message}
        setValue={setMessage}
        isValid={validMessage}
        setIsValid={setValidMessage}
        validate
        errorMessage={intl.formatMessage({ id: 'contact.error.message' })}
        firstSubmit={firstSubmit}
      />
      <Input
        type="text"
        label="contact.company"
        placeholder="contact.company.placeholder"
        value={company}
        setValue={setCompany}
        setIsValid={setValidCompany}
        isValid={validCompany}
        errorMessage={intl.formatMessage({ id: 'contact.error.company' })}
        validate
      />
      <div className="grid-cols-none flex space-x-2 order-last">
        <PrimaryButton
          type="submit"
          text={intl.formatMessage({ id: 'contact.submit' })}
          loading={loading}
        />
      </div>
    </form>
  );
};
export default GetInTouchForm;

GetInTouchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
