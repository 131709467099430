import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import InputError from './InputError';

const InputSelect = ({
  label,
  options,
  value,
  setValue,
  emptyMessage,
  firstSubmit,
  validate,
}) => {
  const intl = useIntl();
  const [showError, setShowError] = useState(true);

  return (
    <div>
      <label
        htmlFor={label}
        className="block text-gray-700 text-sm mb-1.5 font-medium"
      >
        {intl.formatMessage({ id: label })}
      </label>
      <select
        id={label}
        defaultValue=""
        name="role"
        className={
          value.length === 0 && firstSubmit
            ? 'border border-red-400 rounded-3p h-11 w-full px-2'
            : 'border border-gray-light rounded-3p h-11 w-full px-2'
        }
        onChange={(e) => {
          validate && setValue(e.target.value);
        }}
      >
        <option value="" disabled>
          {intl.formatMessage({ id: 'contact.role.placeholder' })}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <InputError
        className={value.length === 0 && firstSubmit ? '' : 'invisible'}
      >
        {value.length === 0 ? emptyMessage : ''}
      </InputError>
    </div>
  );
};

export default InputSelect;

InputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
