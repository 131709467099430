import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { AlertCircle } from 'react-feather';
import PropTypes from 'prop-types';
import InputError from './InputError';
import validators from '../../utils/validators';

const Input = ({
  type,
  label,
  placeholder,
  value,
  setValue,
  errorMessage,
  emptyMessage,
  isValid,
  setIsValid,
  firstSubmit,
  validate,
}) => {
  const intl = useIntl();
  const name = label.replace('contact.', '');
  const validator = validators[name];
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    setIsValid(validator(value));
  }, [value]);

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-gray-700 text-sm mb-1.5 font-medium"
      >
        {intl.formatMessage({ id: label })}
      </label>
      <div className="relative">
        <input
          autoComplete="off"
          name={name}
          id={name}
          className={
            (!isValid && value.length !== 0) ||
            (value.length === 0 && firstSubmit)
              ? 'border border-red-400 rounded-3p h-11 w-full py-2 px-3 text-gray-700'
              : 'border border-gray-light rounded-3p h-11 w-full py-2 px-3 text-gray-700'
          }
          value={value}
          type={type}
          placeholder={intl.formatMessage({ id: placeholder })}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            if (
              (validate && !validator(value)) ||
              (validate && value.length === 0)
            ) {
              setShowError(true);
            } else if (validate || (validate && value.length === 0)) {
              setShowError(false);
            }
          }}
          onFocus={() => {
            validate && setShowError(false);
          }}
        />
        <AlertCircle
          color="rgb(248 113 113)"
          size={15}
          className={
            !isValid && value.length !== 0 && showError
              ? 'absolute right-3 inset-y-0 my-auto'
              : 'hidden'
          }
        />
      </div>
      <InputError
        className={
          (!isValid && value.length !== 0 && showError) ||
          (value.length === 0 && firstSubmit && showError)
            ? ''
            : 'invisible'
        }
      >
        {!isValid && value.length !== 0 && showError
          ? errorMessage
          : value.length === 0 && showError
          ? emptyMessage
          : ''}
      </InputError>
    </div>
  );
};
export default Input;

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
