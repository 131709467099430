import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import InputError from './InputError';

const InputArea = ({
  label,
  placeholder,
  value,
  setValue,
  isValid,
  setIsValid,
  errorMessage,
  validate,
  firstSubmit,
}) => {
  const intl = useIntl();
  const name = label.replace('contact.', '');

  const validator = (value) => value.length < 500;

  return (
    <div className="order-1 lg:order-none">
      <label
        htmlFor={name}
        className="block text-gray-700 text-sm mb-1.5 font-medium"
      >
        {intl.formatMessage({ id: label })}
      </label>
      <textarea
        name={name}
        id={name}
        className={
          !isValid && value.length !== 0
            ? 'border border-red-400 rounded-3p h-11 w-full py-2 px-3 text-gray-700 h-28 align-top'
            : 'border border-gray-light rounded-3p h-11 w-full py-2 px-3 text-gray-700 h-28 align-top'
        }
        placeholder={intl.formatMessage({ id: placeholder })}
        rows="8"
        onBlur={(e) => {
          validate && setValue(e.target.value);
          if (validate && !validator(e.target.value)) {
            setIsValid(false);
          } else if (validate) {
            setIsValid(true);
          }
        }}
        onFocus={(e) => {
          validate && setIsValid(true);
        }}
      ></textarea>
      <InputError className={!isValid && value.length !== 0 ? '' : 'invisible'}>
        {!isValid && value.length !== 0 ? errorMessage : ''}
      </InputError>
    </div>
  );
};
export default InputArea;
