import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { navigate } from 'gatsby';
import Layout from '../../components/layout';
import { H15 } from '../../components/Texts/index';

import contact from '../../images/get-in-touch.png';
import GetInTouchForm from '../../components/GetInTouchForm';

const GetInTouch = () => {
  const intl = useIntl();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    fetch(process.env.GET_IN_TOUCH_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formProps),
    }).then(() => {
      navigate(`${formProps.role}`);
    });
  };

  return (
    <Layout>
      <div className="flex items-center mx-auto max-w-7xl">
        <div className="px-4 py-6 lg:pt-16 lg:pl-32 lg:w-7/12 mb-16 lg:flex-shrink-0">
          <p className="mb-3 text-green-700">
            {intl.formatMessage({ id: 'contact.form.subtitle' })}
          </p>
          <H15 className="mb-3">
            {intl.formatMessage({ id: 'contact.form.heading' })}
          </H15>
          <p className="mb-6">
            {intl.formatMessage({ id: 'contact.form.text1' })}
          </p>
          <GetInTouchForm onSubmit={handleSubmit} />
        </div>
        <div className="hidden lg:flex">
          <img src={contact} alt="contact" />
        </div>
      </div>
    </Layout>
  );
};

export default GetInTouch;
